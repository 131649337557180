<template>
  <div>
    <van-nav-bar
      title="报告结果"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="report">
      <h1 class="title">测评报告</h1>
      <div class="instruction-section">
        <h2>一、测评说明</h2>
        <div class="instruction-list">
          <div class="instruction-item">
            测评从心理健康状况（心理痛苦）、工作/人际/生活事件困扰等方面对员工的心理健康状况及潜在风险进行评估。
          </div>
          <div class="instruction-item ordered">
            1.
            测评结果供您初步了解员工心理健康风险状况，不能作为临床诊断的依据。
          </div>
          <div class="instruction-item ordered">
            2.
            测评基于员工自我评价，会受员工作答时的态度、动机、是否理解题目等因素影响。
          </div>
          <div class="instruction-item ordered">
            3.
            请保护员工隐私信息，不要把员工测评信息泄露给相关工作之外的其他人。
          </div>
        </div>
      </div>

      <section class="info-section">
        <h2>二、测评人员信息</h2>
        <div class="info-item">
          <span class="label">姓名：</span>
          <span class="value">{{ ReportPro4HW.name }}</span>
        </div>
        <div class="info-item">
          <span class="label">工号：</span>
          <span class="value">{{ ReportPro4HW.stuff }}</span>
        </div>
        <div class="info-item">
          <span class="label">性别：</span>
          <span class="value">{{ ReportPro4HW.sex }}</span>
        </div>
        <div class="info-item">
          <span class="label">年龄：</span>
          <span class="value">{{ ReportPro4HW.age }}</span>
        </div>
        <div class="info-item">
          <span class="label">所在国家：</span>
          <span class="value">{{ ReportPro4HW.country }}</span>
        </div>
        <div class="info-item">
          <span class="label">婚姻状况：</span>
          <span class="value">{{ ReportPro4HW.marry }}</span>
        </div>
        <div class="info-item">
          <span class="label">恋爱状况：</span>
          <span class="value">{{ ReportPro4HW.relationship }}</span>
        </div>
        <div class="info-item">
          <span class="label">身份：</span>
          <span class="value">{{ ReportPro4HW.identity }}</span>
        </div>
        <div class="info-item">
          <span class="label">是华为员工的：</span>
          <span class="value">{{ ReportPro4HW.kinship }}</span>
        </div>
        <div class="info-item">
          <span class="label">婴幼儿期的陪伴者：</span>
          <span class="value">{{ ReportPro4HW.custodian }}</span>
        </div>
        <div class="info-item">
          <span class="label">测评完成时间：</span>
          <span class="value">{{ ReportPro4HW.endtime }}</span>
        </div>
      </section>
      <section class="result-section">
        <h2>三、测评结果</h2>
        <h3>（一）综合结果</h3>
        <div class="result-item">
          <span class="label">本次测评，员工当前存在：</span>
          <span class="value">{{ ReportPro4HW.fengxian }}</span>
        </div>
        <div class="result-item">
          <span class="label">心理健康（SRQ-20）分值：</span>
          <span class="value">{{ ReportPro4HW.score }}</span>
        </div>
        <div class="result-item">
          <span class="label">自杀意念：</span>
          <span class="value">{{ ReportPro4HW.zisha }}</span>
        </div>
        <div class="result-item">
          <span class="label">心理困扰分值：</span>
          <span class="value">{{ ReportPro4HW.xinli }}</span>
        </div>
      </section>
      <RangeBar
        v-for="(result, index) in tu1"
        :key="index"
        :score="result.score"
        :maxScore="result.maxScore"
        :segments="result.segments"
        :labels="result.labels"
      />
      <div>表1 各风险水平判断条件</div>
      <div>
        <div class="info-item info-item-color-blue">
          <div class="info-item-l">风险等级</div>
          <div style="color: #fff">条件</div>
        </div>
        <div class="info-item info-item-color-fff">
          <div class="info-item-l">高风险</div>
          <div class="info-item-r">
            <div>1.SRQ-20，总分≥12 或</div>
            <div>2.有自杀意念：第17题阳性；</div>
          </div>
        </div>
        <div class="info-item info-item-color-skyblue">
          <div class="info-item-l">中风险</div>
          <div class="info-item-r">1.SRQ-20，总分 [9,11]</div>
        </div>
      </div>
      <div class="info-item info-item-color-fff">
        <div class="info-item-l">低风险</div>
        <div class="info-item-r">
          <div>1.SRQ-20，总分：[6,8]，或</div>
          <div>2.心理困扰（阳性题目数量）≥1</div>
        </div>
      </div>
      <div class="info-item info-item-color-skyblue">
        <div class="info-item-l">当前无情绪风险</div>
        <div class="info-item-r">1.SRQ-20，总分：[0,5]</div>
      </div>

      <section class="questions-section">
        <h2>（二）题目作答详情</h2>
        <h2>心理健康自评（SRQ-20）</h2>
        <div
          class="question"
          v-for="(lb3525, index) in lb3525"
          :key="index"
          v-if="index < 20"
        >
          <span class="text"> {{ lb3525.subject }}</span>
          <div class="options">
            <span class="option red" v-if="lb3525.item[0].checked == '1'"
              >是</span
            >
            <span class="option blue" v-if="lb3525.item[1].checked == '1'"
              >否</span
            >
          </div>
        </div>

        <h2>情绪困扰</h2>
        <div
          class="question"
          v-for="(lb3525, index) in lb3525"
          :key="index"
          v-if="index > 19 && index < 27"
        >
          <span class="text"> {{ lb3525.subject }}</span>
          <div class="options">
            <span class="option red" v-if="lb3525.item[0].checked == '1'"
              >是</span
            >
            <span class="option blue" v-if="lb3525.item[1].checked == '1'"
              >否</span
            >
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import RangeBar from "../huawei/component/RangeBar.vue";

export default {
  components: {
    RangeBar,
  },
  data() {
    return {
      ReportPro4HW: [],

      tu1: [
        {
          title: "焦虑",
          score: 0,
          maxScore: 4,
          segments: [
            { color: "green", flex: 1 },
            { color: "cyan", flex: 1 },
            { color: "qiancheng", flex: 1 },
            { color: "red", flex: 1 },
          ],
          labels: [""],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
    };
  },
  created() {
    console.log(this.$route.query.subId);
    this.subId = this.$route.query.subId;
    this.fetchData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async fetchData() {
      try {
        const response = await fetch(
          "https://assess.eapchina.net/getReportPro4HW?subId=" + this.subId
          // "http://192.168.1.217:8081/assess/getReportPro4HW?subId=" + this.subId
        );
        const data = await response.json();
        this.ReportPro4HW = data;
        this.lb3525 = data.lb3525;
        this.tu1[0].score = data.fengxianScore;
        console.log(data.fengxianScore);
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.report {
  background-color: #f0f9ff;
  padding: 30px;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.6;
  font-size: 14px;
}

h1.title {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 2px solid #4472c4;
}

h2 {
  color: #34495e;
  font-size: 18px;
  margin: 25px 0 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.question {
  padding: 12px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.option {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option.red {
  background: #ffebee;
  color: #e53935;
}

.option.blue {
  background: #e3f2fd;
  color: #1e88e5;
}
.info-item {
  display: flex;
  border-bottom: 1px solid #dee2e6;
  align-items: center;
}

.info-item-color-blue {
  background: #4472c4;
  color: white;
  font-weight: bold;
}

.info-item-color-skyblue {
  background: #e3f2fd;
}

.info-item-l {
  width: 80px;
  padding: 12px;
  display: flex;
  align-items: center;
}

.info-item-r {
  flex: 1;
  padding: 12px;
  line-height: 1.5;
}

/* 修正重复的高风险条目 */
.info-item:nth-child(4) {
  /* 删除重复的高风险条目 */
  display: none;
}
.info-item-color-blue {
  background-color: #4472c4;
}
.info-item-color-skyblue {
  background-color: #b4c6e7;
}
.info-item-color-fff {
  background-color: #ecf1f9;
}
.instruction-section {
  margin: 20px 0;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.instruction-item {
  line-height: 1.6;
  margin: 10px 0;
  padding: 8px 12px;
}

.instruction-item.ordered {
  background: #fff;
  border-left: 3px solid #4472c4;
  padding-left: 15px;
  border-radius: 4px;
}
</style>
