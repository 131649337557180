<template>
  <div class="range-bar">
    <div class="range-bar-track">
      <!-- 各分段颜色 -->
      <div
        v-for="(segment, index) in segments"
        :key="index"
        :class="['range-bar-segment', segment.color]"
        :style="{ flex: segment.flex }"
      ></div>
    </div>
    <!-- 指针 -->
    <div class="range-bar-indicator" :style="{ left: position + '%' }">
      <div class="score">
        <!-- {{ score }} -->
      </div>
    </div>
    <div class="range-bar-labels">
      <span v-for="label in labels" :key="label">{{ label }}</span>
    </div>
    <div style="text-align: center; font-size: 12px">
      "注：越接近绿色区间，风险越低“
    </div>
  </div>
</template>

<script>
export default {
  props: {
    score: {
      type: Number,
      required: true, // 当前分数
    },
    maxScore: {
      type: Number,
      default: 100, // 总分，默认为100
    },
    segments: {
      type: Array,
      default: () => [
        { color: "red", flex: 1 },
        { color: "yellow", flex: 2 },
        { color: "cyan", flex: 3 },
        { color: "green", flex: 3 },
      ],
    },
    labels: {
      type: Array,
      default: () => [
        "0",
        "10",
        "20",
        "30",
        "40",
        "50",
        "60",
        "70",
        "80",
        "90",
        "100",
      ],
    },
  },
  computed: {
    position() {
      return (this.score / this.maxScore) * 100; // 计算指针位置
    },
  },
};
</script>

<style scoped>
.range-bar {
  position: relative;
  margin: 15px 0;
}

.range-bar-track {
  position: relative;
  height: 30px;
  background-color: #e0e0e0;
  display: flex;
  overflow: hidden;
  margin-top: 30px;
}

.range-bar-segment {
  height: 100%;
}

.qiancheng {
  background-color: #ffc000;
}

.medium {
  background-color: #ffa41b;
}

.yellow {
  background-color: #ffd966;
}
.red {
  background-color: #ed7d31;
}
.green {
  background-color: #92d050;
}
.cyan {
  background-color: #c5e0b4;
}
.qinglv {
  background-color: #a9d18e;
}
.shenlv {
  background-color: #00b050;
}

.range-bar-indicator {
  position: absolute;
  top: -20px;
  transform: translateX(-50%);
  z-index: 1000;
  background-image: url("https://lesson.iapeap.com/images/cp/%E5%9D%90%E6%A0%87.png");
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  color: #fff;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  color: #333;
}
.score {
  position: relative;
  left: 18px;
}

.range-bar-labels {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}
</style>
